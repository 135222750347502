import styled from 'styled-components'

export default styled.div`
  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .help-wanted {
    margin-top: 5%;
    @media (max-width: 1400px) {
      font-size: .95em;
      img { width: 250px; height: 250px; }
    }
    @media (max-width: 1200px) {
      font-size: .9em;
      img { width: 250px; height: 250px; }
    }
    @media (max-width: 1000px) {
      font-size: .85em;
      img { width: 200px; height: 200px; }
    }
    @media (max-width: 800px) {
      font-size: .8em;
      img { width: 150px; height: 150px; }
    }
  }
  .help-join {
    .help-inner {
      width: 30%;
      margin-left: auto;
      text-align: left;
      padding: 1rem;
      @media (max-width: 800px) { width: 100%; padding-left: 1rem; margin: .5rem; }
    }
    .help-inner:first-child { border: 1px solid white; }
  }
`