import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Button, Box, useColorMode } from '@chakra-ui/core'
import { useSpring } from 'react-spring'
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'
import HomeStyles from '../components/styles/Home'
import { Clouds, Footer, Header, HelpWanted, Pandora } from '../components'
import { Centertext, Emoji } from '../components/subcomps'

const calcMouse = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const pageCount = 4


export default function Index() {
  let parallax
  const { colorMode, toggleColorMode } = useColorMode('light')
  const [lang, setLang] = useState('EN')
  const [mouseProps, set] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 10,
      tension: 550,
      friction: 140,
    },
  }))

  const scrollAndColor = (index, color) => {
    parallax.scrollTo(index)
    if (color !== colorMode) toggleColorMode()
  }

  return (
    <HomeStyles>
      <Header
        colorMode={colorMode}
        toggleColorMode={toggleColorMode}
        lang={lang}
        setLang={setLang}
        parallax={(id) => parallax.scrollTo(id)}
      />
      <Parallax pages={pageCount} scrolling={true} vertical ref={ref => parallax = ref}>

        {/* extras */}
        <ParallaxLayer offset={.8} speed={.25} style={{marginTop:'20%'}}>
          {/* sun */}
          <div style={{marginLeft:'-10%',width:'600px',height:'600px',borderRadius:'50%',background:'yellow',opacity:'.2'}}></div>
        </ParallaxLayer>

        <ParallaxLayer offset={.95} speed={.5} style={{marginTop:'20%'}}>
          {/* infinity */}
          <div className='infinity' style={{margin:'40% auto 0 10%',textAlign:'left',opacity:'.2'}}></div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.8} speed={.25} style={{marginTop:'20%'}}>
          {/* triangleish */}
          <div style={{marginLeft:'40%',borderTop:'200px solid #5ac9c8',borderLeft:'200px solid transparent',opacity:'.2'}}></div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.9} speed={.5} style={{marginTop:'20%'}}>
          <div className='burst' style={{margin:'-50px 10% 0 auto',textAlign:'left',opacity:'.2'}}></div>
        </ParallaxLayer>

        {/* Page 1 */}
        <ParallaxLayer offset={0} speed={.75}
          onClick={() => scrollAndColor(1, 'light')}
          className='centered'
          onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calcMouse(x, y) })} 
        >
          <Clouds props={mouseProps} />
          <Centertext
            en='Programming is my tool to contribute to modern society'
            jp='Modern Software Developer: Ethical, Professional, Responsible'
          />
        </ParallaxLayer>

        {/* Page 2 */}
        <ParallaxLayer id='1' offset={1} speed={.75} className='centered' >
          <Pandora lang={lang} goNext={() => scrollAndColor(2, 'dark')} />
        </ParallaxLayer>

        {/* Page 3 */}
        <ParallaxLayer id='2' offset={2} speed={.75} factor={1.5} className='centered help-wanted'>
          <HelpWanted lang={lang} />
        </ParallaxLayer>

        {/* Footer */}
        <ParallaxLayer offset={3} speed={.75} className='centered'>
          <Footer lang={lang} goTop={() => parallax.scrollTo(0)} />
        </ParallaxLayer>
      </Parallax>
    </HomeStyles>
  );
}
