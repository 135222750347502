import React from 'react'
import { Flex } from '@chakra-ui/core'

export default ({children}) => (
  <Flex p={4}
    justify='space-between'
    align='center'
    flexDir='row'
    width='100%'
    height='calc(100vh - 100px)'
    minHeight='300px'
  >
    { children }
  </Flex>
);
