import { useEffect, useRef } from 'react'

export default (callback, delay) => {
  const savedCallback = useRef()
  const tick = () =>savedCallback.current()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}