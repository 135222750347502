import React, { useState } from 'react'
import useInterval from '../../hooks/useInterval'
import DenText from './DenText'
import Fullpage from './Fullpage'

/** EN and JP will randomly alternate */
export default ({ en, jp }) => {
  const [lang, set] = useState('EN')

  useInterval(() => {
    set(prev => prev==='EN'?'JP':'EN')
  }, 5000)

  return (
    <Fullpage className='action'>
      <DenText lang={lang} en={en} jp={jp} styles={{fontWeight:'700'}} classname='action' />
    </Fullpage>
  )
}
