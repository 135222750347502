import React, { useEffect } from 'react'
import { useDencrypt } from 'use-dencrypt-effect'
import { Text } from '@chakra-ui/core'


export default ({ lang, en, jp, styles={}, classname='' }) => {

  const { result, dencrypt } = useDencrypt({
    interval: 5,
  })

  useEffect(() => {
    dencrypt(lang==='EN' ? en : jp)

  // seems bug with useDencrypt + en/jp should be constants
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  return (
    <Text textAlign='center' verticalAlign='middle' width='100%' style={styles} className={classname}>
      { result }
    </Text>
  )
}
